import React, { useEffect, useRef, useState } from 'react'
import './styles.scss'
import { Box, Container, Typography, Grid, Button } from '@mui/material'
import CountUp from 'react-countup';
import image from '../../../assets/images/homePage-image-1.svg'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

const HomePageSecondComponent = () => {

    const [isVisible, setIsVisible] = useState(false);
    const countUpRef = useRef();
    const cookie = new Cookies();

    const handleScroll = () => {
        const element = countUpRef.current;
        if (!isVisible && isElementInViewport(element)) {
            setIsVisible(true);
        }
    };

    const isElementInViewport = (el) => {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    useEffect(() => {

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isVisible]);
    return (
        <Grid container className='HomePageSecondComponentContainer'>
            <Grid item md={7} xs={12} className="HomePageSecondComponentTitleContainer">
                <img src={image} width='95%' height='95%' />
            </Grid>
            <Grid item md={5} xs={12} className='HomePageSecondComponentContentContainer'>
                <Box className='HomePageSecondComponentBoxContainer'>
                    <Typography variant='h2' className='HomePageSecondComponentTitle'>
                        Fortify your future with digital security.
                    </Typography>
                    <Typography variant='span18' className='HomePageSecondComponentSubTitle'>
                        Harnessing advanced data analytics, our smart security solution proactively
                        guards digital assets. It learns and adapts, crafting a dynamic shield against
                        evolving threats.<br /><br />
                        With each insight, we refine our defenses, offering compact, intelligent protection
                        that not only secures but anticipates, ensuring seamless safety in the digital realm.
                    </Typography>
                    <Box sx={{ marginTop: "32px" }}>
                        <Typography variant='span18' className="HomePageSecondComponentBoxTitle">
                            <div ref={countUpRef} style={{ marginBottom: '16px' }}>
                                {isVisible && (
                                    <>
                                        <CheckRoundedIcon className='HomePageSecondComponentBoxCheck' /> +<CountUp start={0} end={250} duration={1} />K Monitoring
                                    </>
                                )}
                            </div>
                        </Typography>
                        <Typography variant='span18' className="HomePageSecondComponentBoxTitle">
                            <div ref={countUpRef} style={{ marginBottom: '16px' }}>
                                {isVisible && (
                                    <>
                                        <CheckRoundedIcon className='HomePageSecondComponentBoxCheck' /> +<CountUp start={0} end={25} duration={2} />K Surveillance
                                    </>
                                )}
                            </div>
                        </Typography>
                        <Typography variant='span18' className="HomePageSecondComponentBoxTitle">
                            <div ref={countUpRef} style={{ marginBottom: '16px' }}>
                                {isVisible && (
                                    <>
                                        <CheckRoundedIcon className='HomePageSecondComponentBoxCheck' /> +<CountUp end={92} duration={1} />% Optimality
                                    </>
                                )}
                            </div>
                        </Typography>

                    </Box>

                    {
                        !cookie.get('token') &&
                        <Link to='/signin'>
                            <Button className="HomePageSecondComponentButton">
                                <Typography variant='span16'>
                                    Get Started
                                </Typography>
                            </Button>
                        </Link>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}

export default HomePageSecondComponent