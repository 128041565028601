import React, { useState } from 'react';
import './styles.scss';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { systemAccordion } from '../../../data';

const SystemThirdComponent = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box className='SystemThirdComponentWrapper'>
            <Typography variant='h4' className='SystemThirdComponentTitle' gutterBottom>
                Why Us!
            </Typography>
            {systemAccordion.map((item) => (
                <Accordion
                    key={item.id}
                    expanded={expanded === item.id}
                    onChange={handleChange(item.id)}
                    className='SystemThirdComponentAccordion'
                    elevation={0} // No box shadow by default
                    sx={{ margin: '10px 0', borderBottom: '1px solid #ccc' }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${item.id}-content`}
                        id={`${item.id}-header`}
                    >
                        <Typography fontWeight='bold'>{item.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className='SystemThirdComponentContentText'>
                            {item.content}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
}

export default SystemThirdComponent;
