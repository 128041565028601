import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { system } from '../../../data';
import './styles.scss';

const SystemSecondComponent = () => {

    return (
        <>
            <Box sx={{ margin: '0px 16px', display: 'flex', flexWrap: "wrap", alignItems: "center", justifyContent: "center", gap: '16px' }}>
                {
                    system?.map((section, index) => {
                        return (
                            <Box class="notification">
                                <Box class="notiglow"></Box>
                                <Box class="notiborderglow"></Box>
                                <Box class="notititle">{section?.title}</Box>
                                <Box class="notibody">{section?.content}</Box>
                            </Box>
                        )
                    })
                }

            </Box>
        </>
    )
}

export default SystemSecondComponent