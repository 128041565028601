import React from 'react';
import './styles.scss';
import { Box, Typography, Button, TextField, Grid } from '@mui/material';
import moment from 'moment';
import logo from '../../../assets/images/exsecure-w.png';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

const Footer = () => {
    const cookie = new Cookies();

    return (
        <Box className="footerWrapper">
            <Grid container spacing={2} padding='24px'>
                <Grid item xs={12} sm={12} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                    <img src={logo} width={85} height={85} alt='footer-logo' />
                </Grid>
                <Grid item xs={12} sm={12} display='flex' alignItems='center' flexDirection='column'>
                    <Typography variant='h6' color='white' fontWeight='bold' marginBottom={2}>Links</Typography>
                    <Box display='flex' flexDirection='row' alignItems='flex-start' justifyContent='space-evenly' width='50%'>
                        <Link to="/" className='footerLink'>Home</Link>
                        <Link to="/about" className='footerLink'>About Us</Link>
                        <Link to="/scenarios" className='footerLink'>Scenarios</Link>
                        <Link to="/system" className='footerLink'>System</Link>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
                    <Typography variant='h6' color='white' fontWeight='bold' marginBottom={0}>Stay Connected</Typography>
                    <Link to={cookie?.get('token') ? '/contact-us' : '/signin'}>
                        <Button variant="contained" sx={{ mt: 2 }} fullWidth className='footerButton'>Get in Touch</Button>
                    </Link>
                </Grid>
            </Grid>
            <Box className='footerCopyRight'>
                <Typography variant='span16'>
                    ©{moment().format('YYYY')} MsRecover - All Rights Reserved
                </Typography>
            </Box>
        </Box>
    );
}

export default Footer;
