import React from 'react'
import './styles.scss'
import { Box, Container, Typography } from '@mui/material'
import { aboutUs } from '../../../data'
import { arabToRoman } from 'roman-numbers'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const AboutUsSecondComponent = () => {
    return (
        <Box className="AboutUsSecondComponentWrapper">
            <Container sx={{ padding: '32px 0px', display: "flex" }}>
                <Box className="AboutUsSecondComponentTitlesContainer">
                    <Typography variant='h2' className='AboutUsSecondComponentTitle'>
                        Reasons to Partner With Us
                    </Typography>
                    <Typography variant='span18' className='AboutUsSecondComponentSubTitle'>
                        Committed to enhancing your digital experience, we provide a secure and nurturing environment for your growth.
                        Trusting us ensures your digital realm is managed with care and professionalism.
                    </Typography>
                    <Box sx={{ marginTop: "24px" }}>
                        <Typography variant='spanM' className='AboutUsSecondComponentText'>
                            <CheckRoundedIcon className='AboutUsSecondComponentBoxCheck' /> Dedicated to improving your online interactions, we offer a secure and supportive setting that fosters your development.
                        </Typography>
                        <br />
                        <br />
                        <Typography variant='spanM' className='AboutUsSecondComponentText'>
                            <CheckRoundedIcon className='AboutUsSecondComponentBoxCheck' /> By choosing our services, you entrust your digital world to a team that handles it with meticulous care and professionalism."
                        </Typography>
                        <br />
                        <br />
                        <Typography variant='spanM' className='AboutUsSecondComponentText'>
                            <CheckRoundedIcon className='AboutUsSecondComponentBoxCheck' /> We are committed to providing an environment that not only enhances your digital experience but also ensures its safety and continuous growth.
                        </Typography>
                        <br />
                        <br />
                        <Typography variant='spanM' className='AboutUsSecondComponentText'>
                            <CheckRoundedIcon className='AboutUsSecondComponentBoxCheck' />
                            Our commitment to enhancing your digital experience ensures a safe and nurturing space where you can confidently grow and innovate.
                        </Typography>
                        <br />
                        <br />
                        <Typography variant='spanM' className='AboutUsSecondComponentText'>
                            <CheckRoundedIcon className='AboutUsSecondComponentBoxCheck' />
                            Entrusting us with your digital needs guarantees that your virtual environment is managed with the utmost care and professional diligence.
                        </Typography>
                    </Box>
                </Box>
                <Box className='AboutUsSecondComponentCardsContainer'>
                    {
                        aboutUs?.map((about, index) => {
                            return (
                                <Box class="AboutUsSecondComponentCardsContainerCard">
                                    <Box class="AboutUsSecondComponentCardsContainerBox">
                                        <Box class="AboutUsSecondComponentCardsContainerContent">
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <h2 style={{ marginRight: "8px" }}><b>{arabToRoman(index + 1)}</b></h2>
                                                <h4> {about?.title}</h4>
                                            </Box>
                                            <p>{about?.subTitle}</p>
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        })
                    }


                </Box>
            </Container>
        </Box>
    )
}

export default AboutUsSecondComponent