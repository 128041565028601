import React from 'react'
import './styles.scss'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const AboutUsFourthComponent = () => {
    return (
        <Box className="AboutUsFourthComponentWrapper">
            <Box className="AboutUsFourthComponentContainer">
                <Typography variant='h2' className='AboutUsFourthComponentTitle'>
                    Empower Yourself Now
                </Typography>
                <Typography variant='h3' className='AboutUsFourthComponentSubTitle'>
                    Enhance Your Digital Safety Today.
                </Typography>

                <Link to='/signin' className='AboutUsFourthComponentLink'>
                    <Button className='AboutUsFourthComponentButton'>
                        Get in Contact
                    </Button>
                </Link>
            </Box>
        </Box>

    )
}

export default AboutUsFourthComponent