import React from 'react'
import { Box } from '@mui/material'
import SlideShow from '../../component/homepage/slieshow'
import HomePageFirstComponent from '../../component/homepage/homePageFirstComponent'
import HomePageSecondComponent from '../../component/homepage/homePageSecondComponent'
import HomePageThirdComponent from '../../component/homepage/homePageThirdComponent'
import HomePageFourthComponent from '../../component/homepage/homePageFourthComponent'
import './styles.scss'
import PageIntroduction from '../../component/common/introduction'
import bg from '../../assets/images/homePage-into-img.svg'

const HomePage = () => {
    return (
        <>
            <PageIntroduction
                title="Cultivate Authentic Engagement, Overcome Fraudulent Schemes!"
                description1="Discover our triumphs in thwarting unauthorized account access and"
                description2="combatting incentive abuse within the entertainment industry."
                img={bg}
                hp={true}
            />
            {/* <SlideShow /> */}
            <HomePageFourthComponent icon2 />
            <HomePageSecondComponent />
            <HomePageThirdComponent />
            <HomePageFirstComponent />
        </>
    )
}

export default HomePage