import React from 'react'
import AboutUsFourthComponent from '../../component/aboutus/aboutUsFourthComponent'
import AboutUsSecondComponent from '../../component/aboutus/aboutUsSecondComponent'
import AboutUsThirdComponent from '../../component/aboutus/aboutUsThirdComponent'
import PageIntroduciton from '../../component/common/introduction'
import bg from '../../assets/images/about-us-intro.png'


const AboutUs = () => {
    return (
        <>
            <PageIntroduciton img={bg} title="More" description1="Our extensive knowledge in cybersecurity provides us with a" description2='thorough grasp of the challenges you face.' />
            <AboutUsSecondComponent />
            <AboutUsThirdComponent />
            <AboutUsFourthComponent />
        </>
    )
}

export default AboutUs