import React from 'react'
import PageIntroduciton from '../../component/common/introduction'
import ScenariosFirstComponent from '../../component/scenarios/ScenariosFirstComponent'
import AboutUsFourthComponent from '../../component/aboutus/aboutUsFourthComponent'
import bg from '../../assets/images/scenarios-introduction.svg'

const Scenarios = () => {
    return (
        <>
            <PageIntroduciton img={bg} title="Use Cases" description1="Scenarios are imagined sequences of events that depict potential" description2='outcomes, aiding in the process of planning and decision-making.' />
            <ScenariosFirstComponent />
            <AboutUsFourthComponent />
        </>
    )
}

export default Scenarios