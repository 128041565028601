import React from 'react';
import './styles.scss';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const SystemFirstComponent = () => {
    return (
        <Box className='systemFirstComponent'>
            <Card className="systemFirstComponentCard" elevation={0}>
                <CardContent>
                    <Grid container spacing={3} justifyContent="center" alignItems="stretch">
                        <Grid item xs={12} className="systemFirstComponentMainContent">
                            <Typography variant="h5" component="h2" gutterBottom className='systemFirstComponentContentTitle'>
                                Advanced Security Framework
                            </Typography>
                            <Typography variant="body1" paragraph className='systemFirstComponentContent'>
                                Our integrated system provides robust protection for online businesses, addressing risks associated with web-based financial transactions and electronic transfers. It ensures secure user interactions while enabling seamless integration for rapid deployment.
                            </Typography>

                            <Typography variant="h6" component="h3" gutterBottom className='systemFirstComponentContentTitle'>
                                Core Features of Our Framework
                            </Typography>
                            <Typography variant="body1" paragraph className='systemFirstComponentContent'>
                                Our system's agility allows for customized security configurations, blending cutting-edge technology with behavioral analytics to maintain convenience alongside safety. Utilize our comprehensive dashboard to implement effective fraud prevention measures across your operations.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} className="systemFirstComponentSidebar">
                            <Box className='systemFirstComponentSideContent'>
                                <Typography variant="subtitle1" gutterBottom className='systemFirstComponentContentTitle'>
                                    <CheckRoundedIcon className='systemBoxCheck' /> Unified Management Dashboard
                                </Typography>
                                <Typography variant="body2" paragraph className='systemFirstComponentContent'>
                                    Combine diverse functionalities into one ecosystem, streamlining operations and simplifying tech administration.
                                </Typography>
                            </Box>
                            <Box className='systemFirstComponentSideContent'>
                                <Typography variant="subtitle1" gutterBottom className='systemFirstComponentContentTitle'>
                                    <CheckRoundedIcon className='systemBoxCheck' /> Seamless Integration
                                </Typography>
                                <Typography variant="body2" paragraph className='systemFirstComponentContent'>
                                    Seamlessly integrate our platform into your existing workflows, ensuring a user-friendly experience for all.
                                </Typography>
                            </Box>
                            <Box className='systemFirstComponentSideContent'>
                                <Typography variant="subtitle1" gutterBottom className='systemFirstComponentContentTitle'>
                                    <CheckRoundedIcon className='systemBoxCheck' /> Flexible and Scalable
                                </Typography>
                                <Typography variant="body2" paragraph className='systemFirstComponentContent'>
                                    Adapt our platform to evolving security needs, ensuring proactive protection against emerging threats.
                                </Typography>
                            </Box>
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
}

export default SystemFirstComponent;
