import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import platform from '../../../assets/images/icons/platform.png';
import capabilities from '../../../assets/images/icons/capabilities.png';
import behavior from '../../../assets/images/icons/behavior.png';
import eye from '../../../assets/images/icons/eye.png';
import './styles.scss';
import bg from '../../../assets/images/homePage-image-2.svg'

const HomePageThirdComponent = () => {
    return (
        <>
            {/* <Box className="HomePageThirdComponentWrapper">
                <Box className="HomePageThirdComponentTitlesContainer">
                    <Typography variant='h2' className='HomePageThirdComponentTitle' fontWeight='bold'>
                        Enhanced Fraud Prevention and Cybersecurity Solutions
                    </Typography>
                    <br />
                    <Typography variant='span18' className='HomePageThirdComponentSubTitle'>
                        Our platform combines a user-friendly interface with powerful AI-driven
                        fraud prevention capabilities, designed for seamless integration into your
                        existing systems. By leveraging our extensive cybersecurity expertise and
                        innovative behavioral analytics, we deliver a secure, efficient,
                        and adaptable solution that enhances your operational effectiveness
                        and fortifies user experiences across various platforms.
                    </Typography>

                </Box>
            </Box>
            <Box className='HomePageThirdComponentDataContainer' >
                <Box className="HomePageThirdComponentDataBox">
                    <Box className="HomePageThirdComponentDataImage">
                        <img src={platform} width={70} height={70} />
                    </Box>
                    <Typography variant='h6' className="HomePageThirdComponentDataText">Single Platform</Typography>
                </Box>
                <Box className="HomePageThirdComponentDataBox">
                    <Box className="HomePageThirdComponentDataImage">
                        <img src={capabilities} width={70} height={70} />
                    </Box>
                    <Typography variant='h6' className="HomePageThirdComponentDataText">Varied Capabilities</Typography>
                </Box>
                <Box className="HomePageThirdComponentDataBox">
                    <Box className="HomePageThirdComponentDataImage">
                        <img src={behavior} width={70} height={70} />
                    </Box>
                    <Typography variant='h6' className="HomePageThirdComponentDataText">Interaction-based</Typography>
                </Box>
                <Box className="HomePageThirdComponentDataBox">
                    <Box className="HomePageThirdComponentDataImage">
                        <img src={eye} width={70} height={70} />
                    </Box>
                    <Typography variant='h6' className="HomePageThirdComponentDataText">Seamless</Typography>
                </Box>
            </Box> */}

            < Container className='HomePageThirdComponentWrapper' >
                <Grid container>
                    <Grid item xxl={3} xl={3} lg={3}>
                        <Box className='HomePageThirdComponentContainer'>
                            <Box className="HomePageThirdComponentDataBox">
                                <Box className="HomePageThirdComponentDataImage">
                                    <img src={platform} width={40} height={40} />
                                </Box>
                                <Typography variant='h6' className="HomePageThirdComponentDataText" mt={1}>Unified Interface</Typography>
                                <Typography variant='span16' className="HomePageThirdComponentDataText" mt={1}>Unified system to streamline operations across all business functions.</Typography>
                            </Box>
                            <Box className="HomePageThirdComponentDataBox">
                                <Box className="HomePageThirdComponentDataImage">
                                    <img src={capabilities} width={40} height={40} />
                                </Box>
                                <Typography variant='h6' className="HomePageThirdComponentDataText" mt={1}>Diverse Functionalities</Typography>
                                <Typography variant='span16' className="HomePageThirdComponentDataText" mt={1}>Diverse skills and functions enhancing overall operational efficiency.</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xxl={6} xl={6} lg={6} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src={bg} width='100%' height='100%' />
                    </Grid>
                    <Grid item xxl={3} xl={3} lg={3}>
                        <Box className='HomePageThirdComponentContainer'>
                            <Box className="HomePageThirdComponentDataBox">
                                <Box className="HomePageThirdComponentDataImage">
                                    <img src={behavior} width={40} height={40} />
                                </Box>
                                <Typography variant='h6' className="HomePageThirdComponentDataText" mt={1}>Engagement-Centric</Typography>
                                <Typography variant='span16' className="HomePageThirdComponentDataText" mt={1}>Engagement-driven approach to foster active participation and collaboration.</Typography>
                            </Box>
                            <Box className="HomePageThirdComponentDataBox">
                                <Box className="HomePageThirdComponentDataImage">
                                    <img src={eye} width={40} height={40} />
                                </Box>
                                <Typography variant='h6' className="HomePageThirdComponentDataText" mt={1}>Fluid Integration</Typography>
                                <Typography variant='span16' className="HomePageThirdComponentDataText" mt={1}>Effortless integration ensuring a seamless user experience across various systems and platforms.</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container >
        </>

    )
}

export default HomePageThirdComponent