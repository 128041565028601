import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import pageBackground from '../../../assets/images/page-background.jpg'
import './styles.scss'

const PageIntroduction = ({ title, description1, description2, fullPage, img }) => {
    return (
        <Box className="AboutUsFirstComponentContainer">
            <img src={pageBackground} className='AboutUsFirstComponentImage' alt='background3' />
            <Box className='AboutUsFirstComponentOverlay'>
                <Grid container>
                    <Grid item md={7} sx={{ display: 'flex', justifyContent: "center", flexDirection: "column", p: 2 }}>
                        <Typography variant='h1' className='AboutUsFirstComponentOverlay__Title'>
                            {title}
                        </Typography>
                        <Typography variant='span22' className='AboutUsFirstComponentOverlay__SubTitle'>
                            {description1}<br />{description2}
                        </Typography>
                    </Grid>
                    <Grid item md={5} sx={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                        <img src={img} alt={'pageIntro' + img} width='95%' height='95%' />
                    </Grid>
                </Grid>

            </Box>
        </Box >
    )
}

export default PageIntroduction