import React from 'react'
import './styles.scss'
import { Box, Button, Container, Typography } from '@mui/material'
import { scenarios } from '../../../data'
import TurnRightOutlinedIcon from '@mui/icons-material/TurnRightOutlined';
import { Link } from 'react-router-dom';

const HomePageFourthComponent = ({ icon2 }) => {
    return (
        <Box className="HomePageFourthComponentWrapper">
            <Box className="HomePageFourthComponentTitlesContainer">
                <Typography variant='h2' className='HomePageFourthComponentTitle'>
                    Steering Through Your Everyday Digital Hurdles
                </Typography>
                <Typography variant='span22' className='HomePageFourthComponentSubTitle'>
                    Discover how our tools fortify your online existence.
                </Typography>
            </Box>
            <Box className="HomePageFourthComponentCardsContainer">
                {
                    scenarios?.slice(0, 3)?.map(scenario => {
                        return (
                            <Box className="HomePageFourthComponentCard">
                                <Box className='HomePageFourthComponentCardIconContainer'>
                                    <img src={icon2 ? scenario?.icon2 : scenario?.icon} width={icon2 ? 80 : scenario?.width} height={icon2 ? 90 : scenario?.height} alt={scenario?.name} />
                                    <Typography variant='span22' className='HomePageFourthComponentCardTitle'>
                                        {scenario?.name}
                                    </Typography>
                                </Box>
                                <Box className="HomePageFourthComponentCardTitlesContainer">
                                    <Typography variant='span16' className='HomePageFourthComponentCardSub'>
                                        {scenario?.sub}
                                    </Typography>
                                    {/* <Box className="HomePageFourthComponentButtonContainer">
                                        <Link to='/scenarios'>
                                            <Button className='HomePageFourthComponentButton'>
                                                <TurnRightOutlinedIcon />
                                            </Button>
                                        </Link>
                                    </Box> */}
                                </Box>

                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    )
}

export default HomePageFourthComponent