import React from 'react'
import PageIntroduciton from '../../component/common/introduction'
import SystemFirstComponent from '../../component/system/SystemFirstComponent'
import SystemSecondComponent from '../../component/system/SystemSecondComponent'
import SystemThirdComponent from '../../component/system/SystemThirdComponent'
import AboutUsFourthComponent from '../../component/aboutus/aboutUsFourthComponent'
import bg from '../../assets/images/system-intro.png'

const System = () => {
    return (
        <>
            <PageIntroduciton img={bg} title="Platform" description1='A system is a structured network of components designed' description2='to accomplish a specific goal or function.' />
            <SystemFirstComponent />
            <SystemSecondComponent />
            <SystemThirdComponent />
            <AboutUsFourthComponent />
        </>
    )
}

export default System